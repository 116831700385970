@import '../../../assets/colors.scss';
@import '../../../assets/fonts.scss';

.legalmentions{
  background: $avtb-grey;

  & {
    color: $avtb-white;
  }

  .main{
    padding-top:200px;
    width:80%;
    margin: auto auto;

    a{
      color: $avtb-white;
    }
  }
}