@import '../../../assets/colors.scss';
@import '../../../assets/fonts.scss';

.pagenotfound{
  background: $avtb-grey;

  .titlecontainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    .pagetitle{
      text-align: center;
      font-size: $avtb-page-title;
      color: $avtb-red;
    }
  }
}