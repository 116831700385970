@import '../../../assets/colors.scss';
@import '../../../assets/fonts.scss';

.authentication{
  background: $avtb-grey;


  .formcontainer{
    height:600px;
    display:flex;
    align-items: center;

    .fieldcontainer{
      display: flex;
      flex-direction: column;
      max-width: 400px;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 4px;
      background: #fff;
      text-align: center;

      .formgroup {
        display: flex;
        flex-direction: column;
        margin: 10px 0;
  
        label {
          font-weight: bold;
          margin-bottom: 5px;
        }
  
        input {
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
  
        .showpassword {
          margin-top: 5px;
          cursor: pointer;
          color: $avtb-blue;
          text-decoration: underline;
          border: none;
          background: none;
        }
      }

      .submitauth {
        margin-top: 15px;
        padding: 10px;
        background: $avtb-blue;
        color: $avtb-white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 600px) {
    max-width: 100%;
  }
}