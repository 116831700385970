@font-face {
  font-family: 'Regular';
  src: url('./fonts/Montserrat-Regular.ttf') format('ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Title';
  src: url('./fonts/Montserrat-SemiBold.ttf') format('ttf');
  font-weight: 100;
}

@font-face {
  font-family: 'Italic';
  src: url('./fonts/Montserrat-Italic.ttf') format('ttf');
  font-style:italic;
}

$avtb-page-title: 80px;
$avtb-galerie-month: 60px;
$avtb-h1-size: 28px;
$avtb-h2-size: 24px;
$avtb-p-size: 22px;
$avtb-p-admin-size: 16px;

$avtb-title-font: 'Title', sans-serif;
$avtb-text-font: 'Regular', sans-serif;
$avtb-italic-font: 'Italic', sans-serif;