@import '../../../assets/colors.scss';
@import '../../../assets/fonts.scss';

.footer{
  background-color: $avtb-white;

  & * {
    color: $avtb-grey!important;
    font-size: 22px;
  }

  .firsthalf{
    width: 80%;
    height:200px;
    display:flex;
    margin: auto;
    @media (max-width: 600px) {
      width:calc(100% - 20px);
      a {
        font-size: $avtb-p-admin-size;
      }
    }

    .left{
      width:40%;
      @media (max-width: 600px) {
        width:50%;
      }
    }

    .right{
      width:40%;
      text-align: right;
      @media (max-width: 600px) {
        width:50%;;
      }
    }

    .logocontainer{
      width:20%;
      display:grid;
      place-content: center;
      @media (max-width: 600px) {
        display:none;
      }

      img{
        width:80px;
      }
    }

    .titlecontainer{
      list-style-type: none;
      padding:0px;
      .redirecttitle {
        padding-top:20px;
        a {
          text-decoration: none;
        }
      }
    }
  }

  .secondhalf{
    width: 80%;
    margin: auto;

    .fblogocontainer{
      display:flex;
      place-content: center;
      padding-top:20px;
      .fblogo{
        height:50px;
      }
    }
    .line{
      border-bottom: 5px solid black;
      margin:40px 10% 20px 10%;
    }
    .end{
      width:100%;
      padding-bottom:50px;
      display:grid;
      grid-template-columns: 1fr 3fr 1fr;
      gap: 10px;
      @media (max-width: 600px) {
        grid-template-columns: 1fr 1fr;
      }

      .blankspace{
        @media (max-width: 600px) {
          display:none;
        }
      }

      .rights{
        text-align: center;
        @media (max-width: 600px) {
          font-size: $avtb-p-admin-size;
        }
      }
      .legalmentions{
        text-align: right;
        a {
          text-decoration: none;
          @media (max-width: 600px) {
            font-size: $avtb-p-admin-size;
          }
        }
      }
    }
  }
}