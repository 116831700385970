@import '../../../assets/colors.scss';
@import '../../../assets/fonts.scss';

.homepage{
  background: $avtb-grey;

  & * {
    color: $avtb-white;
  }

  .imagecontainer{
    height:100vh;
    width:100%;
    display:flex;
    justify-content: center;
    overflow:hidden;
    @media (max-width: 600px) {
      padding-top:150px;
      height: auto;
    }
    .mainimage{
      width:100%;
      height:fit-content;
    }
  }
  
  .main{
    width: 80%;
    padding: 10px;
    margin: auto;
    
    .contentseparator{
      margin-top:100px;
    }

    .buttoncontainer{
      display:flex;
      place-content: center center;
    }
    
    .licenseButton{
      background: $avtb-blue;
      font-size: $avtb-h1-size;
      padding:20px;
      border-radius: 30px;
    }
  
    .leftimagecontainer{
      .subcontainerleft{
        width:35%;
        @media (max-width: 600px) {
          display:none;
        }
        img{
          max-width: 100%;
        }
      }
      .subcontainerright{
        margin-top:30px;
        margin-left: 10%;
        width:60%;
        place-content: center;
        @media (max-width: 600px) {
          width:100%;
        }
        .title{
          font-size: $avtb-h2-size;
          font-weight: bold;
        }
        p {
          line-height: $avtb-p-size/2;
          font-size: $avtb-p-size;
          @media (max-width: 600px) {
            line-height: $avtb-p-size;
          }
        }
      }
    }

    .rightimagecontainer{
      .subcontainerright{
        max-width:35%;
        @media (max-width: 600px) {
          display:none;
        }
        img{
          max-width: 100%;
        }
      }
      .subcontainerleft{
        margin-top:30px;
        margin-left: 10%;
        width:60%;
        @media (max-width: 600px) {
          width:100%;
        }
        .title{
          font-size: $avtb-h2-size;
          font-weight: bold;
        }
        p {
          line-height: $avtb-p-size/2;
          font-size: $avtb-p-size;
          @media (max-width: 600px) {
            line-height: $avtb-p-size;
          }
        }
      }
    }
    .leftimagecontainer, .rightimagecontainer{
      width:100%;
      display:flex;
    }

    .lastpicsnavigate{
      text-align: end;
    }
  }
}