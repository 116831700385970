@import '../../../assets/colors.scss';
@import '../../../assets/fonts.scss';

.gallery{
  background: $avtb-grey;

  & * {
    color: $avtb-white;
  }

  .titlecontainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;

    .pagetitle{
      text-align: center;
      font-size: $avtb-page-title;
      color: $avtb-white;
    }
    @media (max-width: 600px) {
      padding-top:150px;
      height: auto;
    }
  }

  .main{
    width:80%;
    margin:auto;
    padding-bottom: 100px;

    .monthcontainer{
      .monthdate{
        font-size: $avtb-galerie-month;
      }
    }

    .buttoncontainer{
      padding-bottom:20px;
      .galleryadminbutton{
        background: $avtb-blue;
        font-size: $avtb-p-size;
        padding:10px;
        border-radius: 20px;
        width:210px;
      }
    }

    .gallerypanel{
      border: 10px solid $avtb-blue;
      margin: -15px;
      padding: 5px 5px 35px 5px;

      .datepickercontainer{
        & * {
          color: initial!important;
        } 
      }
    }
  }
}