@import './assets/fonts.scss';

body {
  margin: 0;
  font-family:  $avtb-text-font;

  h1,h2,h3,h4{
    font-family:  $avtb-title-font;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
