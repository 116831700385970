@import '../../../assets/colors.scss';
@import '../../../assets/fonts.scss';

.mosaic {
  max-width:100%;
  display: grid;
  grid-auto-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;

  .imagecontainer{
    width:inherit;
    position:relative;

    div{
      max-height: inherit;
      display:flex;
      justify-content: center;
      img{
        max-height:inherit;
        max-width:100%;
      }
    }

    .imagedescription{
      text-align: center;
    }

    .removebutton{
      position:absolute;
      top:0px;
      right:0px;
      color: $avtb-red;
      background: $avtb-blue;
      padding: 5px;
      cursor: pointer;
    }
  }
}