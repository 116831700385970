@import '../../../assets/colors.scss';
@import '../../../assets/fonts.scss';

.licenses{
  background: $avtb-grey;

  .titlecontainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;

    .pagetitle{
      text-align: center;
      font-size: $avtb-page-title;
      color: $avtb-white;
    }
    @media (max-width: 600px) {
      padding-top:150px;
      height: auto;
    }
  }

  .main{
    width:80%;
    margin:auto;
    padding-bottom: 100px;

    & * {
      color: $avtb-white;
    }

    .inline3buttoncontainer{
      width: 60%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap:30px;
      margin:auto;
      padding-bottom: 75px;
  
      @media (max-width: 600px) {
        grid-template-columns: 1fr;
        width:100%;

        & > div {
          display:flex;
          place-content: center;
        }
      }
      .mailtolink{
        text-decoration: none;
        .licenseButton{
          background: $avtb-blue;
          font-size: $avtb-p-size;
          padding:15px;
          border-radius: 30px;
          text-align: center;
          width:200px;
          cursor: pointer;
          margin:auto;
        }
      }
    }
  
    .licensedescriptioncontainer{
      padding: 35px 0px;
      .licensedescription{
        border-radius: 50px;
        background:$avtb-white;
        padding:30px;
  
        .textcontainer{
          width:60%;
          margin:auto;
          display:grid;
          grid-template-columns: 1fr 1fr 1fr;
          span{
            color: $avtb-grey;
            text-align: center;
          }
        }
      }      
    }
  }
}