@import '../../../assets/colors.scss';
@import '../../../assets/fonts.scss';

.trainings{
  background: $avtb-grey;

  .imagecontainer{
    height:100vh;
    width:100%;
    display:flex;
    justify-content: center;
    overflow:hidden;
    .mainimage{
      width:100%;
      height:fit-content;
    }
    @media (max-width: 600px) {
      padding-top:150px;
      height: auto;
    }
  }
  
  .main{
    width:80%;
    margin:auto;
    padding-bottom: 100px;

    & * {
      color: $avtb-white;
    }

    .pagetitle {
      font-size: $avtb-h1-size;
    }

    .contentcontainer{
      position:relative;

      .trainingsmosaic {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        padding-bottom: 100px;
        @media (max-width: 600px) {
          grid-template-columns: repeat(2, 1fr);
        }

        .trainingbloc{
          position:relative;
  
          img {
            width: 100%;
          }
  
          .textcontainer {
            position: absolute;
            bottom: 0px;
            width:100%;
            display:none;
            @media (max-width: 600px) {
              display:unset;
            }
            .hiddentext {
              font-size: $avtb-p-size;
              text-align: center;
            }
          }
  
          &::before {
            content:"";
            height: 300px;
            width: 100%;
            background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
            position: absolute;
            bottom:0px;
            display: none;
            @media (max-width: 600px) {
              height:100px;
              display:unset;
            }
          }
  
          &:hover {
            .textcontainer{
              display: unset;
            }
            &::before{
              display:unset;
            }
          } 
        }
      }
  
      .trainingsmodal{
        position:absolute;
        top: calc(50% - 250px);
        left: calc(50% - 350px);
        background: $avtb-grey;
        width:700px;
        height:500px;
        border-radius: 50px;

        @media (max-width: 600px) {
          width:100%;
          height:500px;
          margin: auto;
          left: unset;
          border: 1px solid $avtb-white;
        }

        .modaltitle {
          padding: 50px 0px;
          font-size: $avtb-h2-size;
          text-align: center;
        }

        .modalcontent {
          width:70%;
          margin:auto;
          @media (max-width: 600px) {
            width:90%;
          }

          p {
            font-size: $avtb-p-size;
          }

          .separator {
            width:100%;
            padding: 10px 0px;
            border-bottom: 2px solid $avtb-white;
          }
        }
      }
    }
  }
}