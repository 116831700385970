@import '../../../assets/colors.scss';
@import '../../../assets/fonts.scss';

.header{
  z-index: 10;
  background-color: $avtb-grey;
  height: 110px;
  width:100%;
  position: fixed;

  & * {
    color: $avtb-white;
  }

  .headercontainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    .firsthalf{
      padding-left:20%;
    }
  
    .secondhalf{
      padding: 0px 20% 0px 10%;
      display:grid;
      grid-template-columns: 1fr 1fr 1fr;
      .titlecontainer{
        display:grid;
        margin: auto 0px;
        padding-left:10px;
        a{
          text-decoration: none;
          font-size: 26px;
          padding-left:10px;
          text-align: center;
        }
      }
    }
  }

  .headermobile{
    display:grid;
    grid-template-columns:  1fr 1fr 1fr;

    .headertitle{
      align-content: center;
      font-size: $avtb-p-size;
    }
    .logocontainer{
      margin:10px;
    }
  }
}