@import '../../../assets/colors.scss';
@import '../../../assets/fonts.scss';

.matchs{
  background: $avtb-grey;

  .titlecontainer{
    display: flex;
    height: 100vh;
    width: 80%;
    margin-right:auto;
    margin-left:auto;

    @media (max-width: 600px) {
      padding-top:150px;
      height: auto;
      flex-wrap: wrap;
    }

    & * {
      color: $avtb-white;
    }

    .titlecontainerdivision{
      display:flex;
      justify-content: center;
      align-items: center;
      width:50%;
      height:100%;

      @media (max-width: 600px) {
        width:100%;
      }

      .pagetitle{
        font-size: $avtb-page-title;
        height:max-content;
        width: 400px;
      }

      .titlematchcontainer{
        .matchcategory{
          text-align: center;
          font-size: 60px;
        }
        .matchcontainer{
          display:flex;
          position:relative;

          .firstclub, .separator, .secondclub{
            img{
              margin-left:auto;
              margin-right:auto;
              display:flex;
              width:150px;
              @media (max-width: 600px) {
                width:50px;
              }
            }          
            height: max-content;
            font-size: $avtb-p-size;
            padding: 0px 20px;
          }

          .separator{
            font-size:250px;
            line-height: 160px;
          }
        }
        .matchdate{
          text-align: center;
          font-size: $avtb-h2-size;
        }
      }
    }
  }

  .main{
    width: 80%;
    padding: 10px;
    margin: auto;

    & * {
      color: $avtb-white;
    }

    .categorycontainer {
      .categorytitle {
        font-size: 60px;
      }
      .matchlinecontainer {
        display: flex;
        @media (max-width: 600px) {
          flex-wrap: wrap;
        }

        .matchkey {
          width:calc(33% - 50px);
          padding:50px;
          @media (max-width: 600px) {
            width:100%;
            padding:0px;
          }

          .matchcontainer{
            display:flex;
            position:relative;
      
            .firstclub, .separator, .secondclub{
              img{
                margin-left:auto;
                margin-right:auto;
                display:flex;
                max-width:100px;
                @media (max-width: 600px) {
                  max-width:50px;
                }
              }          
              height: max-content;
              font-size: $avtb-p-size;
              padding: 0px 20px;
            }
      
            .separator{
              font-size:200px;
              line-height: 128px;
              @media (max-width: 600px) {
                font-size:150px;
              }
            }
          }
          .matchdate {
            text-align: center;
            font-size: $avtb-p-size;
          }
        }
        }
    }

    .matchpanel{
      position:relative;

      .panelbuttoncontainer{
        padding-bottom: 10px;
        .panelbutton{
          background: $avtb-blue;
          border-radius: 5px;
          padding: 10px;
          outline: none;
          border:none;
        }
      }

      .explication {
        font-family: $avtb-italic-font;
        font-style: italic;
      }

      .createteam, .matchform{
        display:flex;
        @media (max-width: 600px) {
          flex-wrap: wrap;
        }
        padding-bottom: 20px;
        .newname{
          color:white;
          input{
            border-width: 1px;
            border-color: white;
            outline: none;
            background: $avtb-grey;
          }
          input::placeholder{
            color: white;
            opacity: 0.5
          }
        }

        .newlogo{
          padding-left:10px;
          button {
            outline: none;
            border:none;
            color: black;
            background: $avtb-yellow;
          }
        }

        .validation{
          padding-top:50px;
          padding-left:10px;
        }
        
        .datecontainer{
          .datecontainer{
            input{
              border-width: 1px;
              border-color: white;
              outline: none;
              background: $avtb-grey;
            }

          }
        }

        .categorycontainer{
          padding-left:10px;
          .selectcategory, option{
            border-width: 1px;
            border-color: white;
            outline: none;
            background: $avtb-grey;

          }
        }

        .datepickercontainer{
          & * {
            color: initial!important;
          } 
        }
      }

      .panelgridmatch{
        display:grid;
        grid-template-columns: repeat(auto-fit,325px);
        grid-auto-rows: 1fr;
        grid-column-gap: 15px;
        grid-row-gap: 15px;      

        .matchcell{
          position: relative;
          padding:10px;
          background: $avtb-blue;
          border-radius: 5px;

          .matchcontainer{
            display:flex;
            padding-bottom: 25px;

            .firstclub, .separator, .secondclub{
              img{
                display:flex;
                max-width:75px;
              }          
              height: max-content;
              font-size: $avtb-p-admin-size;
              padding: 0px 20px;
              text-align: center;
              .adminclubname{
                height:50px;
                text-overflow: ellipsis;
              }
            }
      
            .separator{
              padding-top:80px;
              font-size:100px;
              line-height: 64px;
            }

          }

          .matchdate {
            bottom: 10px;
            text-align: center;
            font-size: $avtb-p-admin-size;
            position: absolute;
            width:100%
          }

          .removematch{
            cursor: pointer;
            position:absolute;
            top:2px;
            right:5px;
            color:$avtb-red;
            font-size:$avtb-p-size
          }
        }
      }

      .panelteamgrid{
        display: grid;
        grid-template-columns: repeat(auto-fit,150px);
        grid-auto-rows: 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        .teamcell{
          background: $avtb-blue;
          border-radius: 5px;
          padding: 0px 10px;
          position: relative;

          img{
            max-height: 200px;
            max-width: 100%;
          }

          .teamtitle{
            margin:5px;
          }

          .removeteam{
            cursor: pointer;
            position:absolute;
            top:2px;
            right:5px;
            color:$avtb-red;
            font-size:$avtb-p-size
          }
        }
        .canbeselected{
          cursor: pointer;
        }

        .matchteam1 {
          border: 5px solid $avtb-red;
        }

        .matchteam2 {
          border: 5px solid $avtb-yellow;
        }
      }
    }
  }
}
